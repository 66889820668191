@import '../../layout/variables.scss';

.counter {
  padding: 40px 0;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 380px;
  height: auto;
  padding: 40px 20px;
  border: 1px solid #ccc;
  box-shadow: 0px 2px 2px #ccc;

  h3 {
    border-bottom: 1px dotted #ffbe44;
    padding-bottom: 5px;
  }

  .dataPoint {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    margin: 5px 0;
    width: 100%;

    .value {
      font-weight: bold;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: baseline;
    }
  }

  .buttons {
    display: flex;
    width: 100%;
    flex-flow: column nowrap;
    align-items: center;
    button {
      margin: 5px 0;
    }
  }
}
